<template>
  <div class="aticles">
 
<vue-loadmore  
 :on-loadmore="onLoadMore" 
 :finished="finished">
    <div  class="post-item" v-for="item,index in aticleList" :key="index">
      <!-- 贴吧 -->
      <div v-if="!item.author">
        <div class="top-bar" >
          <div class="top-bar-l">
            <div class="user-logo">
              <van-image v-if="item.user_info.avatar" class="avater-img" round fit="cover" :src="item.user_info.avatar" />
              <van-image v-else class="avater-img" round fit="cover" :src="defaultImg" />
            </div>
            <div class="user-name">{{ item.user_info.nickname }}</div>
            <div class="send-time">{{ item.create_time }}</div>
          </div>
          <template v-if="isShowFollowBtn">
            <van-button round size="small" type="info" :class="['follow-btn', item.is_follow ? 'active' : '']"
              v-if="userInfo.id !== item.user_info.id" @click="handleFollow(item)"> {{ item.is_follow ? '已关注' : '关注'
              }}</van-button>
          </template>
        </div>
        <div class="contents">
          <div class="title" v-if="item.title">
            <p>{{ item.title }}</p>
          </div>
          <div class="content" v-html="item.content" @click="toPost(item)"></div>
          <div :class="['content', item.parent_post_article.content ? 'parent-content' : '']"
            v-if="item.parent_post_article.content" @click="toPost(item.parent_post_article)">
            <div class="user-name">@{{ item.parent_post_article.user_info_name }}:</div>
            <div v-html="item.parent_post_article.content"></div>
          </div>
        </div>
        <div class="bot-bar">
          <div class="bot-bar-l">
            <div class="share" v-if="isShowCross" @click.stop="toShare(item)">
              <img src="~@/assets/images/share.svg" alt />
              <span> {{ item.repeat_count === 0 ? '分享' : item.repeat_count }} </span>
            </div>
            <div>
              <img src="~@/assets/images/reply.svg" alt @click.stop="toPost(item)" />
              <span>{{ item.comment_count === 0 ? '评论' : item.comment_count }}</span>
            </div>
            <div class="like" @click.stop="handleLikeorCollect(item, 'like')">
              <img v-if="!item.is_like" src="~@/assets/images/like.svg" alt />
              <img v-else src="~@/assets/images/like-active.svg" alt />
              <span>{{ item.like_count === 0 ? '点赞' : item.like_count }}</span>
            </div>
            <div class="collect" @click.stop="handleLikeorCollect(item, 'collect')">
              <img v-if="!item.is_collection" src="~@/assets/images/collect.svg" alt />
              <img v-else src="~@/assets/images/collect-active.svg" alt />
              <span>{{ item.is_collection ? '已收藏' : '收藏' }}</span>
            </div>
          </div>
          <div class="cross" v-if="isShowCross">
            <van-icon name="ellipsis" size="18" @click="showSharePannels(item)" />
          </div>
        </div>
      </div>
      <!-- 新闻 -->
      <div class="item"  v-else @click.stop="handleView(item)">
                        <div class="title">
                            {{ item.title }}
                        </div>
                        <a class="content" v-html="item.content">
                            
                        </a>
                        <div class="source">
                            <span class="time">{{ item.create_time }}</span>
                            <span class="source"> 来源：{{ item.source }} </span>
                            <div class="collect" @click.stop="handleCollectChange(item)">
                                <collect v-if="!item.is_collection" />
                                <collectActive v-else/>
                                <span>
                                        <!-- 收藏 -->
                                     {{item.is_collection ?'已收藏':'收藏' }}
                                    </span>
                            </div>
                        </div>
        </div>
    </div>
    
  </vue-loadmore>



    <div class="message">
      <!-- 出现关注消息提示-->
      <MessagePoint mesageTxt="关注成功" :show="showFollow"></MessagePoint>
      <!-- 出现取消关注消息提示-->
      <MessagePoint mesageTxt="取消关注" :show="cancelFollow"></MessagePoint>
      <!-- 出现删除评论成功提示消息提示-->
      <MessagePoint mesageTxt="删除成功" :show="deletePost"></MessagePoint>
    </div>
    <!-- 分享面板 -->
    <van-share-sheet v-model="showShare" :options="options" @select="handleSelectItem" />
    <van-share-sheet v-model="showsShare" :options="shareOptions" @select="handleSelectItems" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MessagePoint from '@/components/Mobile/MessagePoint'
import {collect, collectActive} from '../../../core/icon';
export default {
  name: 'Aticles',
  components: {
    MessagePoint,
    collect,
    collectActive
  },
  props: {
    aticleList: {
      type: Array,
      default: () => []
    },
    // 是否展示关注按钮
    isShowFollowBtn: {
      type: Boolean,
      default: true
    },
    // 是否展示三个点图标和分享图标
    isShowCross: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // 是否显示分享面板
      showShare: false,
      showsShare: false,
      showFollow: false, //出现关注信息提示
      cancelFollow: false, //出现取消关注信息提示
      deletePost: false, //出现删除评论成功信息提示
      finished: false,
      // 默认头像
      defaultImg: require('@/assets/images/userLogo.jpg'),
      //用户信息
      everyPostInfo: {},
      options: [
        { name: '删除', icon: require('@/assets/images/delete-icon.svg') },
        { name: '微信', icon: 'wechat' },
        { name: '微博', icon: 'weibo' },
        { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'poster' },
      ],
      shareOptions: [
        { name: '碳问', icon: 'wechat-moments' },
        { name: '微信', icon: 'wechat' },
        { name: '微博', icon: 'weibo' },
        { name: '复制链接', icon: 'link' },
        { name: '分享海报', icon: 'poster' },
      ],
    };
  },
  computed: {
    ...mapState(['token', 'userInfo']),

  },

  activated() {
  },

  methods: {
    // 点击关注
    async handleFollow(item) {
      // 当用户已经登录时
      if (this.token) {
        //  想要关注的用户id
        const target_user_id = item.user_info.id;
        let is_follow = item.is_follow;
        // 发起关注请求
        await this.api.
          postFormAPISM(
            {
              target_user_id,
              is_follow: !is_follow,
              remark_name: ""
            },
            "/user/follow"
          );
        if (is_follow) {
          // 弹出取消关注提示
          this.cancelFollow = true;
          setTimeout(() => {
            this.cancelFollow = false;
          }, 500);
        }
        else {
          // 弹出关注提示
          this.showFollow = true;
          setTimeout(() => {
            this.showFollow = false;
          }, 500);
        }
        // 切换是否关注
        item.is_follow = !item.is_follow;
        //  重新获取帖子列表
        this.$emit('getPostList')
        // this.getPostList();
      }
      else {
        // 未登录，跳转回登录页面
        this.$router.push('/login');
      }
    },
      //  新闻收藏
 async handleCollectChange(aticle)
  {
    let is_collections = aticle.is_collection;
                  await this.api.postFormAPISM(
                {
                  article_id: aticle.article_id,
                    is_collection: !is_collections,
                },
                "/article/collection"
            );
             //  重新获取帖子列表
        this.$emit('getPostList')
  },
    //  点赞或收藏
    async handleLikeorCollect(aticle, type) {

      // 当用户已经登录时
      if (this.token) {
        let aticle_id = aticle.id;
        let is_like = aticle.is_like;
        let is_collection = aticle.is_collection;
        if (type === 'like') {
          // 调用点赞接口
          await this.api.
            postFormAPISM(
              {
                post_article_id: aticle_id || aticle.post_article_id,
                is_like: !is_like,
              },
              "/article/like"
            );

        }
        else {
          // 调用收藏接口
          await this.api.
            postFormAPISM(
              {
                post_article_id: aticle_id || aticle.post_article_id,
                is_collection: !is_collection,
              },
              "/article/collection"
            );
          // 切换是否收藏
          // aticle.is_collection = !aticle.is_collection;
        }
        //  重新获取帖子列表
        this.$emit('getPostList')
      }
      else {
        // 未登录，跳转回登录页面
        this.$router.push('/login');
      }
    },
    // 打开删除分享面板
    showSharePannels(everyPostInfo) {

      this.everyPostInfo = everyPostInfo;
      // 如果帖子是自己发布的，显示删除按钮，否则不显示
      if (this.everyPostInfo.user_info.id !== this.userInfo.id) {
        this.options = this.options.filter(val => {
          return val.name !== '删除';
        });
      }
      else {
        if (this.options[0].name !== '删除') {
          this.options.unshift(
            { name: '删除', icon: require('@/assets/images/delete-icon.svg') },
          );
        }
      }
      this.showShare = true;
    },
    // 打开转发分享面板
    toShare(everyPostInfo) {
      this.everyPostInfo = everyPostInfo;
      this.showsShare = true;

    },
    // 选择分享面板的某一项，如删除
    async handleSelectItem(option) {
      //删除帖子
      if (option.name === '删除') {
        try {
          let res = await this.$dialog.confirm({
            message: '确认删除帖子吗',
          })
          // 确认
          if (res == 'confirm') {

            const article_id = this.everyPostInfo.id;
            //  调用删除帖子接口
            let res = await this.api.
              postFormAPISM(
                {
                  post_article_id: article_id,
                },
                "/article/delete"
              );

            // 重新刷新帖子列表
            this.$emit('getPostList')
            this.deletePost = true;
            setTimeout(() => {
              //  // 关闭刷新提示
              this.deletePost = false;
            }, 1000)
          }
        }
        catch (cancel) {
          console.log(cancel);
        }

      }
      //关闭分享面板
      this.showShare = false;
    },
    // 选择分享面板的某一项,如转发
    handleSelectItems(option) {
      this.showsShare = false;
      if (option.name === '碳问') {
        this.$nextTick(() => {
          this.$router.push({ path: '/repeat', query: { post_article_id: this.everyPostInfo.id } });
        })

      }


    },
    // 去帖子详情
    toPost(item) {
      this.$router.push({  //一般栈结构, 
        path: '/post',
        query: {
          id: item.id || item.post_article_id,
        }
      })
    },
    // 去新闻详情
    handleView({ title, article_id }) {
            this.$router.push({
                path: "/news",
                query: {
                    id:article_id,
                },
            });
  },
  //加载更多
  onLoadMore(done)
  {
    //  传给父组件，加载更多收藏内容和点赞内容和贴吧
    this.$emit('getMoreCollectList');
    this.$emit('getMoreLikeList');
    this.$emit('getMoreaticle');
    done();
  }


}
};
</script>

<style lang="less" scoped>
.post-item {
  margin-top: 12px;
  padding: 12px 16px;
  background-color: white;

  &:first-child {
    margin-top: 25px;
  }

  &:last-child {
    margin-bottom: 62px;
  }

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    .top-bar-l {
      display: flex;
      align-items: center;

      .user-logo {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }

      .user-name {
        width: 140px;
        font-size: 16px;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .send-time {
        font-size: 14px;
        opacity: 0.6;
      }
    }

    .follow-btn {
      box-sizing: border-box;
      width: 70px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 8px 16px;
      background-color: #f3f7fa;
      // border-radius: 18px;
      color: #27a1ff;
      border: 1px solid #f3f7fa;
      font-size: 14px;

      &.active {
        color: #ccc;
      }
    }
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 14px;
    line-height: 32px;
  }

  .content {
    display: -webkit-box;
    font-size: 14px;
    margin-bottom: 14px;
    line-height: 32px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;

    & /deep/ img {
      width: 100%;
    }

    /deep/ &.parent-content {
      padding: 10px;
      background: #f5f5f5 !important;
      border-radius: 15px;
      box-sizing: border-box;

      &>.user-name {
        color: rgb(67, 122, 239);
      }

      p,
      span,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        background: #f5f5f5 !important;
      }


    }
  }

  .bot-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .cross img {
      width: 10px;
      height: 10px;
    }

    .bot-bar-l {
      display: flex;
      align-items: center;

      div {
        width: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }

      img {
        margin-right: 10px;
        width: 15px;
        height: 15px;
      }
    }
  }
            &>.item {
                padding: 16px 0 16px;
                // border-bottom: 1px solid rgba(215, 215, 215, 1);
                cursor: pointer;

                &:hover {
                    background-color: #f9f9f9;
                }

                &>.title {
                    font-family: "微软雅黑 Bold", "微软雅黑 Regular", "微软雅黑",
                        sans-serif;
                    font-weight: 700;
                    font-style: normal;
                    font-size: 16px;
                    color: #00000f;
                    cursor: pointer;

                    &:hover {
                        color: #ff2832;
                    }
                }

                &>.content {
                    // display: block;
                    // color: #666c72;
                    font-size: 14px;
                    margin: 20px 0;
                    font-family: "微软雅黑", sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    color: #33353c;
                    line-height: 24px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: none;
                    }

                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }

                &>.source {
                    display: flex;
                    align-items: center;
                    & span {
                        font-size: 12px;
                        padding: 0 0 0 6px;

                        &.time {
                            color: #a7a6a6;
                        }

                        &.source {
                            padding: 0 0 0 19px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            width: 140px;
                        }
                    }
                    &>.collect{
                        display: flex;
                    align-items: center;
                        &>svg{
                            padding-left: 10px;
                            width:18px;
                            height:18px
                        }
                    }
                }
              

                &:not(:first-child) {
                    padding-top: 20px;
                }
            }
        }
</style>